import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Complete from "./Complete";
import Step1 from "./Step1";
import Step2 from "./Step2";

const ProjectRequest = (props) => {
  const [step, setStep] = useState(1);
  var content = "";
  if (step == 1) {
    content = <Step1 />;
  } else if (step == 2) {
    content = <Step2 />;
  } else if (step == 3) {
    content = <Complete />;
  }
  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="main-form"
    >
      <Modal.Header className="justify-content-between">
        {step == 2 && <button className="btn btn-primary" onClick={() => setStep(step - 1)}>Back</button>}
        <Button onClick={props.handleClose}>Close</Button>
      </Modal.Header>
      <Modal.Body className="">
        {content}

        {step == 1 && <a className="anfrage-button" onClick={() => setStep(step + 1)}>Next</a>}
        {step == 2 && <a className="anfrage-button" onClick={() => setStep(step + 1)}>Save</a>}
      </Modal.Body>
    </Modal>
  );
};
export default ProjectRequest;

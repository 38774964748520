import React from "react";
import HeroSection from './HeroSection';
import HeroTopSection from './HeroTopSection';

const HeroMain = () => {
  return (
    <div id="home" className="hero-full">
      <HeroSection />
      <HeroTopSection />
    </div>
  );
};
export default HeroMain;

import React from "react";
import SectionHeader from "../Benefits/BenefitsInner/SectionHeader";
import ProcessCard from "./ProcessCard";
import TrackVisibility from "react-on-screen";
import { Player } from "@lottiefiles/react-lottie-player";
import { Parallax } from "react-scroll-parallax";
import { ParallaxProvider } from "react-scroll-parallax";
import { useScrollDirection } from "../../hooks/useScrollDirection";

const ProcessMain = () => {
  const scrollDirection = useScrollDirection();

  return (
    <section id="process" className="process-main-section">
      <ParallaxProvider>
        <Parallax opacity={[1, 0]}>
          <SectionHeader>
            <h4>Process</h4>
            <h2>
              My workflow to make <br />
              the web flow.
            </h2>
          </SectionHeader>
        </Parallax>
      </ParallaxProvider>
      <div className="process-main">
        <ProcessCard>
          <h2>
            <span>Step 1</span>Project Request
          </h2>
          <p>
            At the beginning of our collaboration is the project request. Your
            information helps me to understand if I am the right person for your
            project. If yes, we will arrange a video call so I can learn more.
          </p>
        </ProcessCard>
        <ProcessCard>
          <h2>
            <span>Step 2</span>First Meeting
          </h2>
          <p>
            Trust is extremely important to me when working together. In our
            initial meeting, we get to know each other and see if we are a good
            fit. We also talk about the exact goals & requirements, clarify
            questions and define the next steps.
          </p>
        </ProcessCard>
        <ProcessCard>
          <h2>
            <span>Step 3</span>Proposal
          </h2>
          <p>
            After I have learned as much as possible about your project, I
            create an individual proposal. I don't charge for my work by the
            hour, but on a per project basis. This way you have a full overview
            of the costs.
          </p>
        </ProcessCard>
        <ProcessCard>
          <h2>
            <span>Step 4</span>Implementation
          </h2>
          <p>
            As soon as I have the required materials, I start with
            implementation. I place particular emphasis on transparency,
            convenient communication channels (like Slack) and regular updates.
          </p>
        </ProcessCard>

        <ProcessCard className="last-card">
          <TrackVisibility>
            {({ isVisible }) =>
              isVisible &&
              scrollDirection === "down" && (
                <div className="complete-lo">
                  <Player
                    autoplay
                    loop={false}
                    src="./assets/lottie/player.js"
                    style={{ height: "300px", width: "300px" }}
                  ></Player>
                </div>
              )
            }
          </TrackVisibility>
          <h2>
            <span>Step 5</span>Launch / Handover
          </h2>
          <p>
            Before the launch, the finished website goes through an internal
            quality assurance in which it is put through its paces. Depending on
            the project, the website will be hosted via Webflow's ultra-fast
            hosting or integrated into another environment via code export.
          </p>
        </ProcessCard>
      </div>
    </section>
  );
};
export default ProcessMain;

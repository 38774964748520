import React from "react";
import BoxStatus from "../BoxStyle/BoxStatus";
import BoxStyle from "../BoxStyle/BoxStyle";

const MainBenefitsBoxes = () => {
  return (
    <div className="main-benefits-boxes">
      <div className="row">
        <div className="col-lg-6">
          <BoxStyle
            style={{
              padding: "64px",
              backgroundColor: "#1d1d1f",
              opacity: "0.9",
            }}
          >
            <h4>
              I have been working in this industry for about 7+ years now.
            </h4>
            <p>
              I keep an eye on industry trends to find the best short- and long-
              term solutions to our problems, and I collaborate closely with the
              founders to match business requirements. Quality comes first for
              me, even if it means giving up some crunch time; writing
              maintainable, testable code is a must.
            </p>
          </BoxStyle>
        </div>
        <div className="col-lg-6">
          <div className="row h-50">
            <div className="col-6 pb-3 pe-3">
              <BoxStyle className="h-100 text-center d-flex align-items-center flex-column justify-content-center">
                <BoxStatus
                  Number="275"
                  NumberIcon="+"
                  StatusName="Happy Clients complete"
                />
              </BoxStyle>
            </div>
            <div className="col-6 pb-3 pe-3">
              <BoxStyle className="h-100 text-center d-flex align-items-center flex-column justify-content-center">
                <BoxStatus
                  Number="350"
                  NumberIcon="+"
                  StatusName="Projects"
                  TextGrad={{
                    backgroundImage: "linear-gradient(135deg,#63e3dc,#1254c2)",
                  }}
                />
              </BoxStyle>
            </div>
          </div>
          <div className="row h-50">
            <div className="col-6 pt-3 pe-3">
              <BoxStyle className="h-100 text-center d-flex align-items-center flex-column justify-content-center">
                <BoxStatus
                  Number="7"
                  NumberIcon="+"
                  StatusName="Years of experience Satisfaction"
                  TextGrad={{
                    backgroundImage: " linear-gradient(225deg,#e374ff,#3d30ea)",
                  }}
                />
              </BoxStyle>
            </div>
            <div className="col-6 pt-3 pe-3">
              <BoxStyle className="h-100 text-center d-flex align-items-center flex-column justify-content-center">
                <BoxStatus
                  Number="100"
                  NumberIcon="%"
                  StatusName="Client"
                  TextGrad={{
                    backgroundImage: "linear-gradient(135deg,#ffb077,#b82698)",
                  }}
                  IconAlign={{
                    verticalAlign: "baseline",
                  }}
                />
              </BoxStyle>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MainBenefitsBoxes;

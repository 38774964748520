import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Step2 = () => {
  const [startDate, setStartDate] = useState(new Date());
  return (
    <div className="main-inner-content step-2-main-content">
      <h4>Step 2</h4>
      <Form>
        <Form.Group
          className="form-inner-group"
          controlId="formBasicCurrentDomain"
        >
          <Form.Label>
            <p>Current Domain Name</p>
            <span>(Optional)</span>
          </Form.Label>
          <Form.Control type="text" />
        </Form.Group>
        <Form.Group
          className="form-inner-group"
          controlId="formBasicCompanyName"
        >
          <Form.Label>
            <p>Company name</p>
            <span>(Optional)</span>
          </Form.Label>
          <Form.Control type="text" />
        </Form.Group>
        <Form.Group className="form-inner-group">
          <Form.Label>
            <p>Type of business</p>
            <span>(Optional)</span>
          </Form.Label>
          <Form.Select>
            <option value={1}>Small</option>
            <option value={2}>Big</option>
            <option value={3}>Large</option>
          </Form.Select>
        </Form.Group>
        <Form.Group className="form-inner-group" controlId="formBasicBudget">
          <Form.Label>
            <p>Budget</p>
          </Form.Label>
          <Form.Control type="number" />
        </Form.Group>
        <Form.Group className="form-inner-group" controlId="formBasicDeadline ">
          <Form.Label>
            <p>Deadline</p>
            <span>(Optional)</span>
          </Form.Label>
          <DatePicker
            dateFormat="yyyy/MM/dd"
            selected={startDate}
            onChange={(date) => setStartDate(date)}
          />
        </Form.Group>
        <Form.Group className="form-inner-group text-box">
          <Form.Label>
            <p>Additional comments</p>
            <span>(Optional)</span>
          </Form.Label>
          <Form.Control as="textarea" rows={3} />
        </Form.Group>
      </Form>
    </div>
  );
};
export default Step2;

import React from 'react'

const Complete = () => {
  return (
    <div className='complete-main'>
      <div className="complete-inner text-center d-flex align-items-center justify-content-center flex-column">
        <span>Confirmation</span>
        <img src="./assets/images/body/check.png" alt="" />
        <h1>Thanks for your request asd.</h1>
        <p>I will be in touch with you as soon as possible.</p>
      </div>
    </div>
  )
}
export default Complete;
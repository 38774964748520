import React from "react";

const BoxStatus = (props) => {
  var TextGrad = {
    backgroundImage: "linear-gradient(225deg, #5af141, #2a9dce)"
  };
  if (props.TextGrad != undefined) {
    var TextGrad = props.TextGrad;
  }
  var IconAlign = {
    verticalAlign: "top"
  };
  if (props.IconAlign != undefined) {
    var IconAlign = props.IconAlign;
  }
  return (
    <div className="box-status">
      <h3 style={TextGrad} className="intro-stats">
        {props.Number} <span style={IconAlign}>{props.NumberIcon}</span>
      </h3>
      <div className="stats-descriptoin">{props.StatusName}</div>
    </div>
  );
};
export default BoxStatus;

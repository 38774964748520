import React from "react";
import BoxStyle from "../BoxStyle/BoxStyle";

const LightningFast = () => {
  return (
    <div className="lightning-fast">
      <div className="row">
        <div className="col-md-6">
          <BoxStyle
            style={{
              padding: "64px",
              backgroundColor: "#1d1d1f",
              opacity: "0.9",
            }}
            className="lightning-fast-left"
          >
            <h4>Lightning-fast load times.</h4>
            <img
              src={"./assets/images/body/Lightning-fast-image.png"}
            />
          </BoxStyle>
        </div>
        <div className="col-md-6">
          <BoxStyle
            style={{
              padding: "0 0 97px",
              backgroundColor: "#1d1d1f",
              opacity: "0.9",
            }}
            className="lightning-fast-right h-100 text-center d-flex flex-column justify-content-between"
          >
            <img
              src={"./assets/images/body/Lightning-fast-image-right.png"}
            />
            <h4>Website <br />development.</h4>
          </BoxStyle>
        </div>
      </div>
    </div>
  );
};
export default LightningFast;

import React from "react";
import BoxStyle from "../BoxStyle/BoxStyle";
import TrackVisibility from "react-on-screen";

const CleanCode = () => {
  return (
    <div className="clean-code">
      <div className="row">
        <div className="col-md-6">
          <BoxStyle
            style={{
              padding: "64px 32px 64px 52px",
              backgroundColor: "rgb(29, 29, 31)",
            }}
            className="clean-code-left"
          >
            <h4>Clean code</h4>
            <div className="intro-card-code-wrap">
              <div>
                <span className="code-color">&lt;ul&gt;</span>
              </div>
              <div className="intro-card-code-indented">
                <span className="code-color">&lt;li&gt;</span>Semantic HTML.
                <span className="code-color">&lt;/li&gt;</span>
              </div>
              <div className="intro-card-code-indented">
                <span className="code-color">&lt;li&gt;</span>Class naming
                system.
                <span className="code-color">&lt;/li&gt;</span>
              </div>
              <div className="intro-card-code-indented">
                <span className="code-color">&lt;li&gt;</span>Optimized for
                performance.<span className="code-color">&lt;/li&gt;</span>
              </div>
              <div>
                <span className="code-color">&lt;/ul&gt;</span>
              </div>
            </div>
          </BoxStyle>
        </div>
        <div className="col-md-6">
          <BoxStyle
            style={{
              padding: "64px 52px",
              backgroundColor: "rgb(29, 29, 31)",
            }}
            className="clean-code-right h-100 d-flex justify-content-center align-items-center"
          >
            <h4>Development duration</h4>
            <TrackVisibility>
              {({ isVisible }) =>
                isVisible ? (
                  <div className="intro-card-dev-speed-wrap">
                    <div className="intro-card-dev-speed-months">
                      <div className="dev-speed-month-line"></div>
                      <div>Months</div>
                    </div>
                    <div className="dev-speed-weeks">Weeks</div>
                  </div>
                ) : (
                  <div className="intro-card-not">
                    <div className="intro-card-dev-not">
                      <div>Months</div>
                    </div>
                  </div>
                )
              }
            </TrackVisibility>
          </BoxStyle>
        </div>
      </div>
    </div>
  );
};
export default CleanCode;

import React from "react";
import BoxStyle from "../BoxStyle/BoxStyle";

const StunningAnimations = () => {
  return (
    <BoxStyle
      style={{
        padding: "64px",
        backgroundImage: "linear-gradient(120deg,#eb4f56,#9d66e9)",
        opacity: "0.9",
      }}
      className="stunning-section"
    >
      <div className="row align-items-center">
        <div className="col-md-6 order-md-2">
          <div className="stunning-video-main">
            <div className="stunning-image">
              <img src={"./assets/images/body/stunning-image.png"} />
            </div>
            <div className="stunning-video">
              <video
                playsInline
                loop
                muted
                autoPlay
                src={"./assets/video/body/stunning-video.mp4"}
              />
            </div>
          </div>
        </div>
        <div className="col-md-6 order-md-1">
          <h4>
            Website design <br />
            Prototyping.
          </h4>
          <p>
            Whether it's subtle interactions that improve the UX, or complex
            "WOW" animations. I can build them all.
          </p>
        </div>
      </div>
    </BoxStyle>
  );
};
export default StunningAnimations;

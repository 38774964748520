import React, { Fragment, useState } from "react";
import "../style/header_footer/header";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import ProjectRequest from "../ProjectRequest/ProjectRequest";
import { Link, animateScroll as scroll } from "react-scroll";

const Header = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = (e) => {
    setShow(true);
  };
  return (
    <Fragment>
      <header className="header-main">
        <div className="container">
          <Navbar
            expand="lg"
            className="header-inner d-flex justify-content-between align-items-center"
          >
            <Navbar.Brand href="#home" className="header-logo">
              Incognito2019
            </Navbar.Brand>
            <div className="mobile-inner">
              <Navbar.Collapse id="basic-navbar-nav" className="header-menu">
                <Nav className="header-inner-menu m-auto">
                  <ul className="m-0 p-0 d-flex">
                    <li>
                      <Link activeClass="active" spy={true} smooth={true} offset={50} to="home">Home</Link>
                    </li>
                    <li>
                      <Link activeClass="active" spy={true} smooth={true} offset={50} to="about">About Me</Link>
                    </li>
                    <li>
                      <Link activeClass="active" spy={true} smooth={true} offset={50} to="skills">Skills</Link>
                    </li>
                    <li>
                      <Link activeClass="active" spy={true} smooth={true} offset={50} to="portfolio">Portfolio</Link>
                    </li>
                    <li>
                      <Link activeClass="active" spy={true} smooth={true} offset={50} to="process">Process</Link>
                    </li>
                  </ul>
                </Nav>
              </Navbar.Collapse>
              <div className="header-req-button">
                <a className="header-req-button-inner" onClick={handleShow}>
                  Project request
                  <svg
                    width="100%"
                    height="100%"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinejoin="round"
                      strokeLinecap="round"
                      strokeWidth="3.5"
                      stroke="currentColor"
                      d="M8 20L16 12L8 4"
                    ></path>
                  </svg>
                </a>
              </div>
              <Navbar.Toggle
                aria-controls="basic-navbar-nav"
                className="mobile-menu"
              >
                <span></span>
                <span></span>
                <span></span>
              </Navbar.Toggle>
            </div>
          </Navbar>
        </div>
      </header>
      <ProjectRequest
        show={show}
        onHide={handleClose}
        handleClose={handleClose}
      />
    </Fragment>
  );
};

export default Header;

import React from "react";
import SectionHeader from "../Benefits/BenefitsInner/SectionHeader";
import ProjectBox from "./ProjectBox";
import { Parallax } from "react-scroll-parallax";
import { ParallaxProvider } from "react-scroll-parallax";

const Projects = () => {
  return (
    <section id="portfolio" className="project-section">
      <div className="container">
        <ParallaxProvider>
          <Parallax opacity={[0, 1]}>
            <SectionHeader>
              <h4>Projects</h4>
              <h2>
                A small selection <br />
                of my projects.
              </h2>
            </SectionHeader>
          </Parallax>
        </ParallaxProvider>
        <ProjectBox />
      </div>
    </section>
  );
};
export default Projects;

import React, { useState } from "react";
import Form from "react-bootstrap/Form";

const Step1 = () => {
  const [validated, setValidated] = useState(false);
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };
  return (
    <div className="main-inner-content step-1-main-content">
      <h4>Step 1</h4>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Group className="form-inner-group" controlId="formBasicName">
          <Form.Label><p>Name</p></Form.Label>
          <Form.Control required type="text" />
        </Form.Group>
        <Form.Group className="form-inner-group" controlId="formBasicEmail">
          <Form.Label><p>Email</p></Form.Label>
          <Form.Control required type="email"/>
        </Form.Group>
        <Form.Group className="form-inner-group" controlId="formBasicPhone">
          <Form.Label><p>Phone Number</p><span>(Optional)</span></Form.Label>
          <Form.Control type="number"/>
        </Form.Group>
        <Form.Group className="form-inner-group">
          <Form.Label>Services</Form.Label>
          <Form.Select required>
            <option value={1}>Mobile App development</option>
            <option value={2}>Web development</option>
            <option value={3}>Web design</option>
            <option value={4}>App redesign</option>
            <option value={5}>UI/UX</option>
            <option value={6}>Other</option>
          </Form.Select>
        </Form.Group>
        <Form.Group className="form-inner-group text-box">
          <Form.Label><p>Text Box</p></Form.Label>
          <Form.Control as="textarea" rows={3} />
        </Form.Group>
      </Form>
    </div>
  );
};
export default Step1;

import "./App.scss";

import Header from "./components/header/Header";
import Body from "./components/body/Body";
import Footer from "./components/footer/Footer";
import AnimatedCursor from "react-animated-cursor";
import "./components/font/font";
import "../src/components/style/body/body";

function App() {
  return (
    <div className="App">
      <div className="cursor-poin">
        <AnimatedCursor
          innerSize={0}
          outerSize={20}
          color="160, 160, 160"
          outerAlpha={0.4}
          innerScale={1}
          outerScale={3}
          trailingSpeed={1}
        />
      </div>
      <Header />
      <Body />
      <Footer />
    </div>
  );
}

export default App;

import React, { useState, useRef } from "react";
import { useSpring, animated, config } from "react-spring";
import ProjectModal from "./ProjectModal";

const cards = [
  {
    id: "1",
    image: "./assets/images/body/project-image-1.jpg",
    url: "assets/images/body/site-img-1.jpg",
  },
  {
    id: "2",
    image: "./assets/images/body/project-image-2.jpg",
    url: "assets/images/body/site-img-1.jpg",
  },
  {
    id: "3",
    image: "./assets/images/body/project-image-3.jpg",
    url: "./assets/images/body/site-img-1.jpg",
  },
  {
    id: "4",
    image: "./assets/images/body/project-image-4.jpg",
    url: "./assets/images/body/site-img-1.jpg",
  },
  {
    id: "5",
    image: "./assets/images/body/project-image-5.jpg",
    url: "./assets/images/body/site-img-1.jpg",
  },
  {
    id: "6",
    image: "./assets/images/body/project-image-6.jpg",
    url: "./assets/images/body/site-img-1.jpg",
  },
  {
    id: "7",
    image: "./assets/images/body/project-image-7.jpg",
    url: "./assets/images/body/site-img-1.jpg",
  },
  {
    id: "8",
    image: "./assets/images/body/project-image-8.jpg",
    url: "./assets/images/body/site-img-1.jpg",
  },
];

function ProjectBox() {
  const [show, setShow] = useState(false);
  const [modalUrl, setModalUrl] = useState("");

  const handleClose = () => {
    //document.body.classList.remove("bg-salmon");
    setShow(false);
  };
  const handleShow = (e) => {
    //document.body.classList.add("bg-salmon");
    const x = cards.find(x => x.id === e.currentTarget.id);
    setModalUrl(x.url);
    setShow(true);
  };
  const [counter, setCounter] = useState(4);
  const [isDataFinish, setIsDataFinish] = useState(false);
  const loadDataHandler = () => {
    setCounter(counter + 2);
    if (counter >= cards.length - 2) {
      setIsDataFinish(true);
    }
  };
  var showData = cards.slice(0, counter);
  return (
    <div className="main">
      <div className="container">
        <div className="main-box-pro">
          {showData.map((card) => (
            <div
              onClick={handleShow}
              id={card.id}
              key={card.id}
              className="inner-col-box-pro"
            >
              <div>
                <Card>
                  <Image ratio={card.imageRatio} src={card.image} />
                  <div className="project-item-close-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                    >
                      <g
                        fill="none"
                        fillRule="evenodd"
                        stroke="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      >
                        <path
                          stroke="currentColor"
                          strokeWidth="2"
                          d="M10 1.515v16.97M18.485 10H1.515"
                        ></path>
                      </g>
                    </svg>
                  </div>
                </Card>
              </div>
            </div>
          ))}
        </div>
        {!isDataFinish && (
          <div className="load-more-btn">
            <a onClick={loadDataHandler} className="load-more-button">
              Load more
            </a>
          </div>
        )}
      </div>
      <ProjectModal
        show={show}
        onHide={handleClose}
        handleClose={handleClose}
        url={modalUrl}
      />
    </div>
  );
}
function Card({ children }) {
  const ref = useRef();
  const [isHovered, setHovered] = useState(false);
  const [animatedProps, setAnimatedProps] = useSpring(() => {
    return {
      xys: [0, 0, 1],
      config: config.default,
    };
  });
  return (
    <animated.div
      ref={ref}
      className="card-box"
      onMouseEnter={() => setHovered(true)}
      onMouseMove={({ clientX, clientY }) => {
        const x =
          clientX -
          (ref.current.offsetLeft -
            (window.scrollX || window.pageXOffset || document.body.scrollLeft));
        const y =
          clientY -
          (ref.current.offsetTop -
            (window.scrollY || window.pageYOffset || document.body.scrollTop));
        const dampen = 40;
        const xys = [
          -(y - ref.current.clientHeight / 2) / dampen,
          (x - ref.current.clientWidth / 2) / dampen,
          1,
        ];
        setAnimatedProps({ xys: xys });
      }}
      onMouseLeave={() => {
        setHovered(false);
        setAnimatedProps({ xys: [0, 0, 1] });
      }}
      style={{
        zIndex: isHovered ? 2 : 1,
        transform: animatedProps.xys.interpolate(
          (x, y, s) =>
            `perspective(600px) rotateX(${-x}deg) rotateY(${-y}deg) scale(${s})`
        ),
      }}
    >
      {children}
    </animated.div>
  );
}
function Image({ ratio, src }) {
  // const images = {
  //   profile: {
  //       profile1: require('../../images/body/project-image-1.jpeg'),
  //   }
  //   };
  return (
    <div className="image-container">
      <div className="image-inner-container">
        <div
          className="ratio"
          style={{
            paddingTop: ratio * 100 + "%",
          }}
        >
          <div className="ratio-inner">
            <img src={src} />
            {/* {require("../../images/body/project-image-1.jpg")} */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectBox;
// const rootElement = document.getElementById("root");
// ReactDOM.render(<App />, rootElement);

import React, { useState } from "react";
import ProjectRequest from "../ProjectRequest/ProjectRequest";
import "../style/header_footer/header";

const Footer = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = (e) => {
    setShow(true);
  };

  return (
    <section className="footer-main">
      <div className="container">
        <div className="footer-inner-top text-center">
          <h4 className="fluid-gradient-heading ">
            Interested in working&nbsp;with&nbsp;me?
          </h4>
          <a className="cta-link" onClick={handleShow}>
            <div className="cta-link-text">Start a project request</div>
            <div className="cta-link-icon">
              <svg
                width="100%"
                height="100%"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                data-reactroot=""
              >
                <path
                  strokeLinejoin="round"
                  strokeLinecap="round"
                  strokeWidth="3.5"
                  stroke="currentColor"
                  d="M8 20L16 12L8 4"
                />
              </svg>
            </div>
          </a>
        </div>
        <div className="footer-inner-bottom">
          <div className="footer-menu-wrap">
            <div className="footer-legal">
              © 2023 incognito2019
            </div>
            <div className="footer__link-wrap">
              <a href="#" className="footer-link">
                8 Reasons for Reactjs
              </a>
              <a                
                href="#"
                className="footer-link"
                style={{ transform: "translate(0px, 0px) scale(1, 1)" }}
              >
                Imprint
              </a>
              <a id="a" href="#" className="footer-link">
                Privacy
              </a>
            </div>
          </div>
        </div>
      </div>
      <ProjectRequest
        show={show}
        onHide={handleClose}
        handleClose={handleClose}
      />
    </section>
  );
};
export default Footer;

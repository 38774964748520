import React from 'react'
import HeroMain from './hero-section/HeroMain';
import '../style/body/body';
import MainBenefits from './Benefits/MainBenefits';
import Projects from './Project/Projects';
import ProcessMain from './Process/ProcessMain';

const Body = () => {
  return (
    <div className='main-body'>
      <HeroMain />
      <MainBenefits />
      <Projects />
      <ProcessMain />
    </div>
  )
}
export default Body; 
import React from 'react'
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const ProjectModal = (props) => {
  return (
    <Modal
        show={props.show}
        onHide={props.handleClose}
        className="project-model-main"
        centered
      >
        <Button onClick={props.handleClose} className="model-close-btn">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
          >
            <g
              fill="none"
              fillRule="evenodd"
              stroke="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            >
              <path
                stroke="currentColor"
                strokeWidth="2"
                d="M10 1.515v16.97M18.485 10H1.515"
              ></path>
            </g>
          </svg>
        </Button>
        <div className="project-model-inner">
          {/* <iframe
            src={props.url}
            frameBorder="0"
            allowFullScreen
            width="100%"
            height="100%"
          ></iframe> */}
          <img src={props.url} />
        </div>
      </Modal>
  )
}

export default ProjectModal;
import React from "react";

const ProcessCard = (props) => {
    var className = '';
  if (props.className != undefined) {
    var className = props.className;
  }
  return (
    <div className={`process-card ${className}`}>
      {
        props.children
      }
    </div>
  );
};
export default ProcessCard;

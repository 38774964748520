import React from "react";

const HeroTopSection = () => {
  return (
    <section className="hero-top-section">
      <div className="hero-top-inner">
        <h2>I assist people in developing their dream apps and websites.</h2>
      </div>
    </section>
  );
};
export default HeroTopSection;

import React from "react";
import BoxStyle from "../BoxStyle/BoxStyle";

const PixelPerfect = () => {
  return (
    <div className="pixel-perfect">
      <div className="row">
        <div className="col-md-4">
          <BoxStyle
            style={{
              padding: "64px 24px",
              opacity: "0.9",
              backgroundSize: "cover,auto",
            }}
            className="pixel-perfect-left text-center h-100 d-flex justify-content-center align-items-center"
          >
            <h4>
              Mobile App
              <br />
              development.
            </h4>
          </BoxStyle>
        </div>
        <div className="col-md-8">
          <BoxStyle
            style={{
              padding: "58px 64px 0",
              backgroundColor: "#1d1d1f",
              opacity: "0.9",
            }}
            className="pixel-perfect-right"
          >
            <h4>
              Responsive <br />
              Design.
            </h4>
            <img src={"./assets/images/body/pixel-perfect-right.png"} />
          </BoxStyle>
        </div>
      </div>
    </div>
  );
};
export default PixelPerfect;

import React from "react";

const BoxStyle = (props) => {
  var style = {
    padding: "8px",
    backgroundColor: "#1d1d1f",
    opacity: "0.9",
  };
  if (props.style != undefined) {
    var style = props.style;
  }
  var className = '';
  if (props.className != undefined) {
    var className = props.className;
  }
  return (
    <div className={`main-box ${className}`} style={style}>
      {props.children}
    </div>
  );
};
export default BoxStyle;

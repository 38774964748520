import React from "react";
import CleanCode from "./BenefitsInner/CleanCode";
import LightningFast from "./BenefitsInner/LightningFast";
import MainBenefitsBoxes from "./BenefitsInner/MainBenefitsBoxes";
import PixelPerfect from "./BenefitsInner/PixelPerfect";
import SectionHeader from "./BenefitsInner/SectionHeader";
import StunningAnimations from "./BenefitsInner/StunningAnimations";
import { Parallax } from "react-scroll-parallax";
import { ParallaxProvider } from "react-scroll-parallax";

const MainBenefits = () => {
  return (
    <section className="main-benefits">
      <div className="container">
        <ParallaxProvider>
          <Parallax opacity={[0, 1]}>
            <SectionHeader id="about">
              <h4>About Me</h4>
              <h2>
                Your perfect <br />
                Webflow partner.
              </h2>
            </SectionHeader>
          </Parallax>
        </ParallaxProvider>
        <div className="main-benefits-inner" id="skills">
          <MainBenefitsBoxes />
          <StunningAnimations />
          <LightningFast />
          <PixelPerfect />
          <CleanCode />
        </div>
      </div>
    </section>
  );
};
export default MainBenefits;

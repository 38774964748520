import React, { useEffect, useState } from "react";
import ProjectRequest from "../../ProjectRequest/ProjectRequest";

const HeroSection = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };

  const handleShow = (e) => {
    setShow(true);
  };

  useEffect(() => {
    const updatePosition = () => {
      setScrollPosition(window.pageYOffset);
    }
    window.addEventListener("scroll", updatePosition);
    updatePosition();
    return () => window.removeEventListener("scroll", updatePosition);
  }, []);

  // console.log(scrollPosition);
  let scrollClass = 'hero-section';
  if(scrollPosition >= 500){
    scrollClass = "hero-section hero-not-scroll";
  }
  return (
    <div className="hero-main-section">
      <section
        data-0="background-size: 500% auto;"
        data-50="background-size: 450% auto; position: fixed; top:0;"
        data-100="background-size: 400% auto; position: fixed; top:0;"
        data-150="background-size: 350% auto; position: fixed; top:0;"
        data-200="background-size: 300% auto; position: fixed; top:0;"
        data-250="background-size: 250% auto; position: fixed; top:0;"
        data-300="background-size: 200% auto; position: fixed; top:0;"
        data-350="background-size: 150% auto; position: fixed; top:0;"
        data-400="background-size: 100% auto; position: fixed; top:0;"
        data-450="background-size: 50% auto; position: fixed; top:0;"
        data-500="background-size: 50% auto; position: fixed; top:0;"
        data-501="background-size: 50% auto; position: fixed; top:0;"
        className={scrollClass}
      >
        <div
          className="hero-inner"
          data-0="will-change: transform;transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);transform-style: preserve-3d;"
          data-50="will-change: transform;transform: translate3d(0px, -0.8%, 0px) scale3d(0.98, 0.98, 0.98) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);transform-style: preserve-3d;"
          data-100="will-change: transform;transform: translate3d(0px, -1.8%, 0px) scale3d(0.96, 0.96, 0.96) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);transform-style: preserve-3d;"
          data-150="will-change: transform;transform: translate3d(0px, -2.8%, 0px) scale3d(0.94, 0.94, 0.94) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);transform-style: preserve-3d;"
          data-200="will-change: transform;transform: translate3d(0px, -3.8%, 0px) scale3d(0.92, 0.92, 0.92) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);transform-style: preserve-3d;"
          data-250="will-change: transform;transform: translate3d(0px, -4.8%, 0px) scale3d(0.8, 0.8, 0.8) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);transform-style: preserve-3d;"
          data-300="will-change: transform;transform: translate3d(0px, -5.8%, 0px) scale3d(0.75, 0.75, 0.75) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);transform-style: preserve-3d;"
          data-350="will-change: transform;transform: translate3d(0px, -6.8%, 0px) scale3d(0.7, 0.7, 0.7) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);transform-style: preserve-3d;"
          data-400="will-change: transform;transform: translate3d(0px, -7%, 0px) scale3d(0.6, 0.6, 0.6) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);transform-style: preserve-3d;"
          data-450="will-change: transform;transform: translate3d(0px, -7.8%, 0px) scale3d(0.5, 0.5, 0.5) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);transform-style: preserve-3d;"
          data-501="will-change: transform;transform: translate3d(0px, -8%, 0px) scale3d(0.4, 0.4, 0.4) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);transform-style: preserve-3d;"
        >
          <div className="hero-image-main text-center">
            <div className="hero-image">
              <div className="hero-inner-image">
                <img src={"./assets/images/body/hero-image.jpg"} />
              </div>
            </div>
            <div className="hero-content">
              <h2>Incognito2019</h2>
              <div className="hero-heading-ui-wrap">
                <div
                  className="hero-heading-ui"
                  data-0="opacity: 0;"
                  data-250="opacity: 0.5;"
                  data-501="opacity: 1;"
                >
                  <div className="hero-heading-ui-tab">
                    <div className="hero-heading-ui-tab-h1">H1</div>
                    <div>hero heading</div>
                  </div>
                </div>
                <h1 className="hero-heading">Full Stack Developer.</h1>
              </div>
              <a className="cta-link d-flex align-items-center justify-content-center">
                <div className="cta-link-text" onClick={handleShow}>Start a project request</div>
                <svg
                  width="100%"
                  height="100%"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinejoin="round"
                    strokeLinecap="round"
                    strokeWidth="3.5"
                    stroke="currentColor"
                    d="M8 20L16 12L8 4"
                  ></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </section>
      <ProjectRequest
        show={show}
        onHide={handleClose}
        handleClose={handleClose}
      />
    </div>
  );
};
export default HeroSection;

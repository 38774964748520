import React from "react";

const SectionHeader = (props) => {
  var className = '';
  if (props.className != undefined) {
    var className = props.className;
  }
  var id = '';
  if (props.id != undefined) {
    var id = props.id;
  }
  return (
    <div className={`section-header ${className}`} id={id}>
      {props.children}
    </div>
  );
};
export default SectionHeader;
